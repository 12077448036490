.pagination > li > a,
.pagination > li > span {
    background-color: $colorWhite;
    border: 0;
    color: $colorFern;
    margin: 3px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $colorFern;
    border-color: $colorFern;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: $colorAlabaster;
    border-color: $colorAlabaster;
    color: #333;
    border-radius: 4px;
}
