.estates-short-list {
    position: relative;

    ul {
        background: none;
        border: 0;
        float: left;
        list-style-type: none;
        margin: 0 1% 20px;
        padding: 17px 0 0;
        width: 98%;

        &.dropdown-menu {
            background-color: $colorWhite;
            z-index: 2000;
            position: absolute;
        }

        li {
            margin-bottom: 20px;

            .estate-card {
                float: left;
                width: 100%;
                padding: 2%;
                background-color: $colorWhite;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                transition: all 0.2s;

                &:hover {
                    transform: translateY(-4px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

                    .estate-img-container img {
                      transform: scale(1.2);
                    }
                }

                .col-md-6 {
                    width: 100%;
                }
                .col-md-6.row {
                    margin-left: 0;
                    margin-right: 0;
                }
                &.inactive {
                    opacity: 0.7;
                }
            }
            .safe-bar {
                position: relative;
                z-index: 1000;
                width: 26px;
                float: right;
                height: 25px;
                margin-bottom: -25px;
                text-align: center;
                font-family: 'open_sansregular', Arial, sans-serif;

                &.active {
                    width: auto;
                    float: none;

                    span {
                        display: inline-block;
                    }

                    .safe-star {
                        &.active {
                            color: #F3E9E4;
                        }
                    }
                }

                span {
                    display: none;
                    text-transform: uppercase;
                    color: $colorWhite;
                    line-height: 25px;
                    background-color: grey;
                    width: 100%;
                    opacity: 0.85;
                }

                .safe-star {
                    font-size: 24px;
                    color: #F3E9E4;
                    float: right;
                    margin: 1px 2px 0 0;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &.active {
                        color: $colorPromoted;
                    }
                }
            }
            .estate-img-container {
                position: relative;
                width: 100%;
                float: left;
                margin-bottom: 10px;
                /** Lazy-load images with preserved space: */
                height: 0;
                padding-bottom: 75%;
                overflow: hidden;

                img {
                    width: 100%;
                    max-width: 100%;
                    transition: transform 0.5s ease;
                }
                .estate-price {
                    background-color: $colorFern;
                    bottom: 3px;
                    color: $colorWhite;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 18px;
                    padding: 5px 12px;
                    position: absolute;
                    right: 3px;
                    opacity: 1;
                    border-radius: 3px;
                    font-weight: 700;
                }

                &.preview {
                    img {
                        opacity: 0.5;
                    }
                }
            }

            .estate-type {
                color: #3b3b3b;
                font-size: 12px;
                font-style: normal;
            }
            .estate-title {
                font-family: 'Ubuntu', sans-serif;
                font-size: 17px;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                float: left;
                overflow: hidden;
                color: #3d3d3d;
                font-weight: 500;
                letter-spacing: -0.6px;
            }
            .estate-city {
                font-family: 'open_sansregular', Arial, sans-serif;
                clear: both;
                color: #707070;
                margin-bottom: 5px;
                font-size: 13px;
                text-transform: uppercase;
            }
            .estate-desc {
                display: none;
            }

            .estate-surface {
                .glyphicon {
                    margin-right: 5px;
                }
            }

            .estate-card.promoted {
                background-color: $colorWhite;
            }
        }
    }
}

.estates-list {
    ul {
        li {
            .estate-card {
                padding: 1%;

                .col-md-6 {
                    width: 50%;
                }
            }
            .estate-img-container {
                margin-bottom: 0;
            }
            .estate-desc {
                display: block;
                font-size: 13px;
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 0;
                padding-bottom: 33.7%;
                width: 100%;
            }
            &.col-md-3 {
                width: 50%;
            }
            .col-md-6.row {
                padding-left: 0;
            }
        }
    }
}
.estates-list-types {
    position: absolute;
    right: 12px;
    top: 15px;
}
.estates-list-sort {
    position: absolute;
    right: 100px;
    top: 15px;
}

/** RWD **/
@media (max-width: 1200px) {
    .estates-list ul li .estate-card .col-md-6 {
        width: 50%;
    }
}

@media (max-width: 995px) {
    .estates-list {
        ul {
            li {
                .estate-desc {
                    padding-bottom: 18%;
                }
                .estate-card {
                    padding: 2%;

                    .col-md-6 {
                        width: 100%;
                    }
                    .estate-img-container {
                        margin-bottom: 10px;
                    }
                }
                .col-md-6.row {
                    padding-left: 15px;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .estates-list {
        ul {
            li {
                .estate-card {
                    padding: 2%;
                }
                .col-md-6.row {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .estates-short-list {
        ul li .estate-title {
            font-size: 15px;
        }
    }
}

@media (max-width: 767px) {
    .estates-list {
        ul {
            li {
                .estate-desc {
                    padding-bottom: 0;
                    height: auto;
                }
                .estate-card {
                    .col-md-6 {
                        width: 100%;
                    }
                }
                &.col-md-3 {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 567px) {
    .estates-list-types {
        margin: 0 1% 15px;
        position: relative;
        right: auto;
        top: auto;
        width: 98%;

        button {
            width: 50%;
        }
    }
    .estates-list-sort {
        margin: 0 2% 15px;
        position: relative;
        right: auto;
        top: auto;
        width: 96%;
    }
}
