.topinfo {
    display: block;
    width: 100%;
    height: 300px;
    background: url('/img/topinfo.webp') top center no-repeat;
    position: relative;
}

.sub, .search {
    .topinfo {
        display: none;
    }
}

@media (max-width: 768px) {
    .topinfo {
        display: none;
    }
}

.search-container {
    position: relative;

    .content {
        width: 100%;
        float: left;
        border-bottom: 0;
        margin-bottom: 10px;
    }
}

.search {
    border-bottom: 10px solid $colorWhite;

    .tab-content {
        background-color: $colorDeYork;
        float: left;
        padding: 20px 20px;
        width: 100%;
        position: relative;
        z-index: 9000;

        .form-group {
            margin-bottom: 0;
            position: relative;
        }
        select {
            width: 100%;
            float: left;
            margin-right: 10px;
        }
        .search-submit {
            width: 100%;
        }
        .submit {
            width: 100%;
            padding-top: 7px;
            padding-bottom: 7px;
        }
        .search-form > div {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .form-control:focus, .form-control:link {
            box-shadow: none;
            border-color: #ccc;
        }
    }
    .nav-tabs {
        border-bottom: 0;
        text-transform: uppercase;

        li {
            a {
                color: $colorTundora;
                background-color: $colorWhite;
                margin-right: 0;
                border-left: 1px solid $colorWhite;
                border-right: 1px solid #f0f0f0;
                border-radius: 0;
                border-bottom: 0;
                padding: 13px 39px;
                font-family: 'open_sansregular', Arial, sans-serif;
                font-size: 14px;
                outline: 0;
                border-bottom: 1px solid $colorWhite;

                .glyphicon {
                    font-size: 1.1em;
                    position: relative;
                    left: -10px;
                }

                &:hover {
                    .glyphicon {
                        color: $colorGreen;
                    }
                }

                div {
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }

            &.active {
                a {
                    border-color: $colorDeYork;
                    border-top: 1px solid $colorDeYork;
                    background-color: $colorDeYork;
                    color: $colorWhite;

                    &:hover {
                        border-bottom: 1px solid $colorDeYork;

                        .glyphicon {
                            color: $colorWhite;
                        }
                    }

                    div {
                        transition: none;

                        &:hover {
                            transform: none;
                        }
                    }
                }
            }
        }
    }

    .bootstrap-select {
        .bs-searchbox {
            .form-control {
                height: 30px;
            }
        }
    }
}
.dropdown-menu.inner.selectpicker {
    li:first-child {
        font-style: italic;
        font-size: 12px;
    }
}
.select-ajax-search {
    .dropdown-menu.inner.selectpicker {
        li:first-child {
            .text:after {
                content: ' (wpisz minimum 3 znaki)';
            }
        }
    }
}

.advanced-form {
    width: 100%;
    margin: 0 !important;
    background-color: #D4F7D4;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out;
    -webkit-transition: max-height 0.25s ease-out;
    -o-transition: max-height 0.25s ease-out;

    &.visible {
        max-height: 500px;
        transition: max-height 0.35s ease-in;
        -webkit-transition: max-height 0.35s ease-in;
        -o-transition: max-height 0.35s ease-in;
    }

    .adv-field-cont {
        padding-top: 10px;

        &:last-child {
            padding-bottom: 10px;
        }
    }

    input[type=checkbox] {
        float: left;
        margin-right: 7px;
    }

    label {
        font-weight: normal;
    }
}

.advanced-search {
    background-color: $colorDeYork;
    color: $colorWhite;
    position: absolute;
    bottom: -10px;
    z-index: 800;
    left: 0;
    width: 100%;
    border-radius: 0;
    border: 0;

    @media (max-width: 767px) {
        margin-bottom: -12px;
    }

    &:hover {
        background-color: $colorDeYork;
        color: $colorWhite;
    }

    &:visited, &:focus {
        background-color: $colorDeYork;
        color: $colorWhite;
    }

    &:active {
        box-shadow: none;
    }
}

.search-on-map-btn {
    width: 100%;
    background-color: $colorTundora;
    color: $colorAlabaster;
    border-color: $colorTundora;

    &:hover, &:focus {
        background-color: $colorJapaneseLaurel;
        border-color: $colorJapaneseLaurel;
    }

     &.selected {
        background-color: $colorJapaneseLaurel;
        border-color: $colorJapaneseLaurel;

        &:hover, &:focus {
            background-color: $colorTundora;
            border-color: $colorTundora;
        }
    }
}

.map-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background: white;
}

/** RWD **/
@media (max-width: 810px) {
    .search {
        .nav-tabs {
            li {
                a {
                    padding: 13px 19px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .search {
        form {
            padding: 8px 0;
        }
    }
}

@media (max-width: 767px) {
    .search {
        .tab-content {
            padding: 0;
        }
    }
}

@media (max-width: 590px) {
    .search {
        .nav-tabs {
            text-transform: none;
        }
    }
}

@media (max-width: 567px) {
    .search {
        .nav-tabs {
            li {
                a {
                    font-size: 13px;
                    padding: 10px 14px;

                    .glyphicon {
                        left: -2px;
                    }
                }
            }
        }
    }
}

@media (max-width: 495px) {
    .search {
        .nav-tabs {
            li {
                a {
                    font-size: 13px;
                    padding: 10px 7px;

                    .glyphicon {
                        display: none;
                    }
                }
            }
        }
    }
}
